import { h } from 'preact';
import { route, Route, Router } from 'preact-router';

import Header from './header';

//ROUTES
import Home from '../routes/home';
import Login from '../routes/login';
import CorpSelection from '../routes/corpSelection';
import ItemRoute from '../routes/item';
import SettingsRoute from '../routes/settings';
import Profile from '../routes/profile';
import ResetPasswordRoute from '../routes/resetPassword';

//CONTROLLERS
import Notifications from '../controllers/notifications.controller';
import Modals from '../controllers/modals.controller';
import Auth from '../controllers/auth.controller';
import Corp from '../controllers/corp.controller';
import AppController from '../controllers/app.controller';

//UI BLOCKS
import Container from './ui/blocks/container.ui.block';

import '../style/index.css'
import Admins from '../routes/admins';
import Request from '../controllers/request.controller';
import { useEffect, useState } from 'preact/hooks';
import TranslationProvider  from '../translation/provider';

import itaTranslations from '../translation/ita.json'
import engTranslations from '../translation/eng.json'
import Text from './ui/typography/text.ui.typography';


const App = () => {

	const isAuth = Auth.isAuthenticated();

	const {language, languages} = AppController.state 
	// const language = "ita"

	// const languages = ["ita", "eng"]

	useEffect(() => {

		
	}, [AppController.state.language.value])


	return (
			<TranslationProvider
					languages={languages.value as any as string[]}
					translations={
						[itaTranslations, engTranslations]
					}
					selectedLanguage={language.value}
				>
				<Container direction='column' grow={1} height="100%" margin="auto">
							<Container position='fixed' bottom={"0"} right={"0"} width="auto" zIndex={1000} gap="10px" padding={"5px 10px"}>
								
								{AppController.languages.map(lang => (
										<div style="cursor:pointer" onClick={() => AppController.setSelectedLanguage(lang)}>
											<Text bold underlined={AppController.selectedLanguage.value === lang}
											>{AppController.getLanguageLabel(lang)}</Text>
										</div>
								))}
							</Container>
							<Modals.Component />
							<Notifications.Component position="bottom"/> 
							{isAuth && Corp.corpId ? <Header /> : null}

							<Container grow={1} height="100%" overflow='hidden' margin="0 0 20px 0">

								<Container background='transparent' position='fixed' top='0' left='0' width='5px' height='5px' onClick={() => route("/version")}/>

				        <Router onChange={(e) => Auth.verifyRoutes(e)} >

									<Route path="/" component={Home} />

									<Route path="/login" component={Login} />

									<Route path="/corp-selection" component={CorpSelection} />

									<Route path="/corp" component={SettingsRoute} />
							
									<Route path="/item/:id" component={ItemRoute} />
							
									<Route path="/user" component={Profile} />

									<Route path="/admins" component={Admins} />

									<Route path="/reset-password" component={ResetPasswordRoute} />

									<Route path="/version" component={() => <Container direction='column'>V 1.0.5 - {Request.phase}</Container>}/>

				        </Router>

							</Container>

				</Container>
			</TranslationProvider>
	)
}

export default App;
