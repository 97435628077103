import './style/index.css';
import App from './components/app';

document.title = "B1 Card Corp."

const meta = document.createElement("meta")
meta.name="viewport"
meta.content="width=device-width, initial-scale=1, maximum-scale=1"


document.getElementsByTagName("head")[0].appendChild(meta)

export default App;
