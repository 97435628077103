import { signal, computed } from '@preact/signals';
import { MemberUser } from '../interfaces/user.interface';
import { RawItemInterface } from '../interfaces/item.interface';
import Colors from './colors.controller';
import Notifications from './notifications.controller';
import Request from './request.controller';


export class Item {

  rawData: RawItemInterface;

  image = {
    front: "",
    back: ""
  }

  shipped: boolean;
  blocked: boolean;
  active: boolean;

  firstName: string;
  lastName: string;

  fullName: string;
  email: string;
  uid: string;
  jobPosition: string;

  uri: string;
  itemType: number;

  id: number;
  serialId: number;
  profileId: number;

  get status() {
    if (this.blocked) return "testo.misc.statusCardBloccata"
    if (this.active) return "testo.misc.statusCardAttiva"
    if (!this.active && !this.blocked) return "testo.misc.statusCardInattiva"

    return "null"

  }

  get createdAt() {
    const date = new Date(this.rawData.created_at);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear()
    const dateString = `${day}/${month}/${year}`
    return dateString
  }




  constructor(data: RawItemInterface) {

    this.rawData = data

    this.shipped = data.shipped
    this.active = data.active
    this.blocked = data.blocked

    this.firstName = data.corporation_employee_first_name
    this.lastName = data.corporation_employee_last_name

    this.image.back = data.image_url_rear
    this.image.front = data.image_url_front

    this.uri = data.uri
    this.itemType = data.item_type

    this.id = data.id
    this.serialId = data.serial_id
    this.profileId = data.profile_id

    this.fullName = data.user?.display_name ?? ""
    this.email = data.user?.email ?? ""
    this.jobPosition = data.corporation_job_position
    this.uid = data.user?.uid ?? ""

    this.fullName = `${data.corporation_employee_first_name} ${data.corporation_employee_last_name}`
  }

}


interface CorpData {

  vat: string,
  name: string,
  address: string,
  description: string,
  website: string,

}

interface Admin {

  uid: string,
  email: string,
  display_name: string


}

export interface Member {

  uid: string
  email: string,
  role_admin: boolean,
  role_manager: boolean,

}


export default class Corp {

  static colors = Colors;

  private static _state = {
    items: signal<Item[]>([]),
    admins: signal<Admin[]>([]),
    managers: signal<Admin[]>([]),
    id: signal<number | null>(null),
    loading: signal<boolean>(true),
    name: signal(""),
    vat: signal(""),
    website: signal(""),
    address: signal(""),
    description: signal(""),
    itemsPerPage: 20,
    customCorpLogo: signal("")
  }

  static get corpLogo() {
    const logo = this._state.customCorpLogo.value;
    return logo
    // return !!logo ? logo : `${Request.basePath}/v1/corporations/${Corp.corpId}/logo`
  }

  static setCorpLogo(url: string) {
    this._state.customCorpLogo.value = url;
  }

  static setState(key: keyof typeof this._state, value: any) {
    if (this._state[key]) (this._state[key] as any).value = value;
  }

  static async fetchAdmins() {
    try {

      const admins = (await Request.corp(this.corpId).get("/admins")).data

      this.setState("admins", admins)

      return admins

    } catch (err) {

    }
  }

  static async fetchManagers() {
    try {

      const managers = (await Request.corp(this.corpId).get("/managers")).data

      this.setState("managers", managers)

      return managers

    } catch (err) {

    }
  }

  static async createMember(member: Member) {

    try {

      const data = (await Request.corp(this.corpId).post("/members", member)).data

      return data

    } catch (err) {

      throw err
    }

  }

  static async deleteMember(member: MemberUser, memberType: "admin" | "manager") {

    try {

      const d = {
        uid: member.uid
      }

      d[`role_${memberType}`] = true

      const data = (await Request.corp(this.corpId).post("/members", d)).data

      return data

    } catch (err) {

      throw err
    }

  }

  static async fetchItems(prefs?: { search?: string, cursor?: number }, filters: string[] = []) {
    try {
      const id = this._state.id.value
      let reqString = `/${id}/items?`;


      // if(preferences){
      //     if(preferences.search) reqString = `${reqString}?search=${preferences.search}`
      // }

      const preferences = {
        limit: this._state.itemsPerPage,
        filter: filters.join(","),
        ...prefs
      }

      const queryArray = Object.keys(preferences ?? {}).map(key => `${key}=${preferences[key]}`)
      reqString += (queryArray.length ? `${queryArray.join("&")}` : "")



      const rawItems = (await Request.corps.get(reqString)).data as RawItemInterface[]

      const items = rawItems.map(item => {
        return new Item(item)
      })

      this._state.items.value = [...items]

      return items
    } catch (err) {
      console.log(err)
      console.error("ERROR FETCHING ITEMS")
    }
  }

  static get corpName() {
    return this._state.name.value
  }

  static get corpId() {
    return this._state.id.value
  }

  static get state() {
    return this._state
  }

  static get itemsPerPage() {
    return this._state.itemsPerPage
  }

  static async setCorpDetails(data: CorpData, update = true) {

    try {
      if (update) await Request.corps.put(`/${this.corpId}/`, data)
      this._state.customCorpLogo.value = `${Request.basePath}/v1/corporations/${this.corpId}/logo`
      document.title = data.name
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        this.setState(key as any, data[key])
      }
    }
    catch (err) {
      throw err
    }
  }

  static async updateItemDetails(
    itemId: number,
    newData: {
      firstName: string, lastName: string, jobPosition: string
    }) {
    try {

      const data = {
        corporation_employee_first_name: newData.firstName,
        corporation_employee_last_name: newData.lastName,
        corporation_job_position: newData.jobPosition
      }

      const update = Request.corps.put(`/${this.corpId}/items/${itemId}`, data)

    } catch (err) {
    }
  }

  static async fetchLogo() {
    try {
      const data = await Request.corps.get(`/${this.corpId}/logo`);
    } catch (err) {
      throw err
    }
  }

  static getItemBySerial(serialId: number) {
    return this._state.items.value.find(item => item.serialId == serialId)
  }

  static getItemById(id: number) {
    return this._state.items.value.find(item => item.id == id)
  }

  static async unlinkItem(uid: string) {
    try {
      await Request.corps.delete(`/${Corp.corpId}/items/${uid}/user`)
    } catch (err) {
      throw new Error(err)
    }
  }

  static async blockItem(uid: string) {
    try {
      await Request.corps.delete(`/${Corp.corpId}/items/${uid}`)
    } catch (err) {
      throw new Error(err)
    }
  }

  static setColor(color: keyof typeof Colors.list, value: string) {
    Colors.setColor(color, value)
  }

  static setItems(items: Item[]) {
    this._state.items.value = items;
  }

  static setName(name: string) {
    this.setState("name", name)
  }

  static get logoUrl() {
    const logo = `${Request.basePath}/v1/corporations/${Corp.corpId}/logo`
    return logo
  }

}
