import {signal, computed} from '@preact/signals'

interface ColorsInterface{
    
}

export default class Colors{

    private static state = {
        primaryColorLight: signal<string>(""),
        primaryColorDark: signal<string>("#000"),
        secondaryColorLight: signal<string>(""),
        secondaryColorDark: signal<string>(""),
        backgroundColor: signal<string>("#ffffff"),
        foregroundColor: signal<string>("#AFCFF4"),
        accentColor: signal<string>("#111976"),
    }
    
    public static sharedState = {
        default: this.state.accentColor.value,
        success: "#a6e9d5",
        alert: "#f13a4d"
    }
    
    static get list(){
        return this.state
    }
    
    static getColors(){
        return this.state
    }
    
    static setColor(color:keyof typeof this.state, value: string){
        this.state[color].value = value;
    };
    
}