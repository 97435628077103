import {signal, computed} from '@preact/signals'
import { FunctionComponent } from 'preact'
import ModalComponent from '../components/modals'

interface ModalData{
    component:FunctionComponent | null,
    title: string,
    width: string | null | undefined
}

interface ModalParams extends Omit<ModalData, "width">{
    component:FunctionComponent,
    title: string,
    width?:string
}


export default class Modals{
    
    private static _state = {
        visible: signal(false),
        data: signal<ModalData>({
            component: null,
            title: "",
            width: null,
        })
    }
    
    static get visible(){
        return this._state.visible.value
    }
    
    static get modalData(){
        return this._state.data.value;
    }
    
    
    static new(params:ModalParams){
        this.toggleVisibility(true);
        this._state.data.value = {...this._state.data.value, ...params};
        this._state.data.value.width = params.width ?? null
    }
    
    static toggleVisibility(val:boolean=!this.visible){
        this._state.visible.value = val
    }
    
    
    static Component = ModalComponent
    
    
}
