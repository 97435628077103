import {signal, computed} from '@preact/signals';
import Request from './request.controller';

interface UserInterface{
    uid: string,
    name: string,
    surname: string,
    email: string,
    phone_number:string
}

interface UserCorpRawInterface{
    role: string
    corporation_id: string,
    corporation_name: string,
    background_color_hex: string,
    foreground_color_hex: string,
    accent_color_hex: string,
}

export default class User{
    
    private static state = {
        user: signal<UserInterface | null>(null),
        fetching: signal(false),
        corps: signal<UserCorpRawInterface[]>([]),
        selectedCorp: signal<number|null>(null)
    }
    
    static async requestUserData(){
        try {
            if(!this.fetching) {
                this.state.fetching.value = true
                const {data} = await Request.users.get("/me");
                this.state.fetching.value = false
                const user = this.setUserData(data);
                return user as UserInterface
            }
            else {
                throw new Error("STILL FETCHING")
            }
        } catch(err){

           
            throw err
        }
    }
    
    static async fetchUserPermissions(uid:string){
        try{
            const {data} = await Request.users.get(`/${uid}/permissions`)
            const corps = data.enabled_corps as UserCorpRawInterface[] | []
            
            if(corps.length) {
                this.state.corps.value = corps; 
                if(corps.length === 1) this.state.selectedCorp.value = Number(corps[0].corporation_id)
            }
            
            return corps
            
        } catch(err){
            console.log("ERRORE FETCHING PERMISSIONS")
        }
    }
    
    static selectCorp(corp:number){
        localStorage.setItem("corpId", String(corp));
        this.state.selectedCorp.value = corp
    }
    
    
    static get userData(){
        return this.state.user.value
    }
    
    static get fetching(){
        return this.state.fetching.value
    }
    
    static get selectedCorp(){
        return this.state.selectedCorp.value
    }
    
    static get userCorps(){
        return this.state.corps.value
    }
    
    static setFetching(value:boolean){
        this.state.fetching.value = value
    }
    
    static async resetPassword(){
        try {
            
            await Request.auth.post("/password-reset", {
                email: this.userData.email
            })

        } catch(err){
            throw err
        }
    }
    
    static async updateUserData(userData:UserInterface){
        try{
            const data = await Request.users.put("/"+userData.uid, userData);
            this.setUserData(userData);

        } catch(err){
            throw new Error(err)
        }
    }
    
    static setUserData(userData:UserInterface){
        this.state.user.value = {...this.userData, ...userData}
        return this.userData
    }
    
}