import { h } from 'preact';
import Container from '../ui/blocks/container.ui.block';
import Text from '../ui/typography/text.ui.typography';
import Corp from '../../controllers/corp.controller';
import Colors from '../../controllers/colors.controller';
import { HamburgerIcon, UserIcon } from '../icons';
import { route } from 'preact-router';
import Modals from '../../controllers/modals.controller';

const Header = () => {
	// const logo = `${Request.basePath}/v1/corporations/${Corp.corpId}/logo`
	const logo = Corp.corpLogo

	function navigate(val: string){
		Modals.toggleVisibility(false)
		route(val)
	}


	function navigationModal(){
		Modals.new({
			title: "ciao",
			width: "100%",
			component: () => <Container direction='column' gap="10px" width='100%'>
				<Text type="title" value="Menu" margin="0 0 20px 0"/>
				<Container gap="10px" alignItems='center' padding="5px 10px" borderRadius='5px' hoverable cursor='pointer' onClick={() => navigate("/")}>
					Cards
				</Container>
				<Container gap="10px" alignItems='center' padding="5px 10px" borderRadius='5px' hoverable cursor='pointer' onClick={() => navigate("/corp")}>
					Corp
				</Container>
				<Container gap="10px" alignItems='center' padding="5px 10px" borderRadius='5px' hoverable cursor='pointer' onClick={() => navigate("/user")}>
					User
				</Container>
			</Container>
		})
	}

	return (
		<Container direction='column'>
			<Container direction="row" justifyContent="space-between" padding="10px" >
				<Container grow={1} alignItems="center" gap="10px">
					<Container maxWidth="78px" maxHeight="78px" borderRadius='100px' overflow="hidden">
						<img src={logo} style="width: 100%; height: 100%"/>
					</Container>
					<Container direction="column" phoneRules='flex-shrink: 1; width: auto;'>
						<Text value={Corp.corpName} type="title"/>
					</Container>
				</Container>
				<Container flex="0" alignItems='center' gap="10px" phoneRules={`display:none`}>
					<Container gap="10px" alignItems='center' padding="5px 10px" borderRadius='5px' hoverable cursor='pointer' onClick={() => route("/")}>
						Cards
					</Container>
					<Container gap="10px" alignItems='center' padding="5px 10px" borderRadius='5px' hoverable cursor='pointer' onClick={() => route("/corp")}>
						Corp
					</Container>
					<Container gap="10px" alignItems='center' padding="5px 10px" borderRadius='5px' hoverable cursor='pointer' onClick={() => route("/user")}>
						User <UserIcon height={24} width={24} />
					</Container>
				</Container>
				<Container flex="0" display='none' phoneRules='display:flex' alignItems='center' margin="0 0 0 20px">
					<Container width="25px" height="25px"  onClick={navigationModal}>
						<HamburgerIcon width={25} height={25}/>
					</Container>
				</Container>
			</Container>

			<Container justifyContent='center' position='fixed' left="0px" bottom="0px" right="0px" width="100vw" maxWidth='100vw' background={Colors.getColors().foregroundColor.value} color={Colors.getColors().backgroundColor.value} padding="5px 0">
				Powered by <a href="b1card.it" style={{color: Colors.getColors().backgroundColor.value, marginLeft: 10}}>B1 Card </a>
			</Container>
		</Container>
	);
}

export default Header;
