import axios from "axios";

export default class Request {

  private static staging = (window as any).STAGING! ?? true;

  static get basePath() {

    return this.staging ?
      "https://api.staging.b1card.it" :
      "https://api.b1card.it";

  }


  private static getAxiosInstance(url: string) {

    return axios.create({
      baseURL: this.basePath + "/v1" + url,
      withCredentials: true,
      headers: {
        post: {
          "Content-Type": "application/json"
        }
      }
    })

  }


  static get auth() {
    return this.getAxiosInstance("/auth")
  }

  static get users() {
    return this.getAxiosInstance("/users")
  }

  static get corps() {
    return this.getAxiosInstance("/corporations")
  }

  static corp(corp: number) {
    return this.getAxiosInstance("/corporations/" + corp)
  }

  static get phase() {
    return this.staging ? "staging" : "production"
  }



}

