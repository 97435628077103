import {signal, computed} from '@preact/signals'
import NotificationComponent from '../components/notifications/index'

export default class Notifications{
    
    private static state = {
        notifications:signal<Notification[]>([]),
    }
    
    private static setState(key:keyof typeof this.state, value:any){
        this.state[key].value = value
    }
    
    private static saveNotification(){
        
    }
    
    private static activeNotifications = computed(()=>{
        return this.getHistory().filter(not => not.active);
    })
    
    static getActiveNotifications(){
        return this.activeNotifications.value;
    }
    
    
    static getHistory(){
        return this.state.notifications.value;
    }
    
    static new(params: NotificationParams){
        const notification = new Notification({
            ...params,
            onDelete: (data:Notification) => this.onNotificationDelete(data),
            id: this.state.notifications.value.length,
        });
        
        this.setState("notifications", [notification,...this.getHistory()]);
    }
    
    
    private static onNotificationDelete(updated:Notification){
        const notificationsUpdated = this.getHistory().map((notification,) => {
            if(notification.id === updated.id){
                return updated
            };
            return notification
        })
        
        
        this.setState("notifications", notificationsUpdated)
    }
    
    static Component = NotificationComponent;
    
    
}

export type NotificationType = "alert" | "error" | "success";

export interface NotificationParams{
    id: number;
    type: "success" | "error" ,
    message?: string,
    subtitle?: string,
    title: string,
    deleteAfterMs?: number,
    onDelete?: (notification: Notification) => void
}

class Notification {

    id: number;
    title:string;
    subtitle:string;
    message: string;
    
    type:NotificationType;
    
    deleteAfterMs:number = 1000 * 2;
    requiresInteraction = false;
    active = true;
    
    color: string;
    
    static colors = {
        success: "#a6e9d5",
        error: "#f1aeb5"
    }
    
    onDelete: null | ((notification: Notification) => void) = null;
    
    constructor(params:NotificationParams){

        this.id = params.id;
        this.title = params.title;
        this.subtitle = params.subtitle ?? "";
        this.message = params.message ?? "";
        this.type = params.type;
        this.deleteAfterMs = params.deleteAfterMs ?? this.deleteAfterMs;
        this.onDelete = params.onDelete ?? null;
        this.color = Notification.colors[params.type] 
        
        
        const _ = this;
        
        if(!this.requiresInteraction){
            setTimeout(function(){
                _.hide(_)
            }, this.deleteAfterMs);
        }
    }
    
    private hide(_:this){
        this.active = false;
        if(this.onDelete!=null) this.onDelete(_);
    }
}