import {h} from 'preact';
import {css} from '@emotion/css';
import { PropsWithChildren } from 'preact/compat';


const fontSizes = {
  heading: 40,
  title: 25,
  subtitle: 20,
  body: 16,
  small: 12,
}


interface TextProps{
    value?: string;
    type?: keyof typeof fontSizes;
    underlined?: boolean
    margin?: string | number;
    bold?:boolean;
    color?:string;
    whiteSpace?:string;
} 

const Text = (props: PropsWithChildren<TextProps>) => {
    
    const Container = css`
      font-size: ${props.type ? fontSizes[props.type] : fontSizes.body}px;
      ${props.color ? `color: ${props.color};` : null};
      ${props.bold? "font-weight: bold;" : null};
      ${props.whiteSpace ? `white-space: ${props.whiteSpace};` : null};
      ${props.underlined ? `text-decoration: underline;` : null}
      margin: ${props.margin ?? "unset"};
    `
    
    return <div class={Container}>
        {props.value || props.children}
    </div>
}

export default Text
