
		import Async from "../../node_modules/.pnpm/@preact+async-loader@3.0.2_preact@10.15.1/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../node_modules/.pnpm/babel-loader@8.3.0_rf5mwho5nu3s3spznxs3423x5y/node_modules/babel-loader/lib/index.js??ref--4-0!../../node_modules/.pnpm/source-map-loader@1.1.3_webpack@4.46.0/node_modules/source-map-loader/dist/cjs.js!./admins.tsx");
				typeof cb === 'function' && cb(result);
			}, "route-admins");
		}

		export default Async(load);
	