import {FunctionComponent, h} from 'preact'
import Modals from '../../controllers/modals.controller'
import Container from '../ui/blocks/container.ui.block'



interface ModalProps{
    
}

const ModalComponent:FunctionComponent<ModalProps> = (props) => {
    
    
    if(!Modals.visible) return

    function closeModal(e){
        Modals.toggleVisibility(false)
    }
    
    return (
        <Container position="fixed" zIndex={1000} background="rgba(0, 0, 0, 0.58)" height='100%' maxWidth='100%' left="0" justifyContent='center' alignItems='center' padding="20px" onClick={closeModal}>
            <Container padding="40px" zIndex={10000} borderRadius='5px' hasShadows background="white" width={Modals.modalData.width ?? "auto"} onClick={e => e.stopPropagation()}>
                {Modals.modalData.component ? <Modals.modalData.component/> : null}
            </Container>
        </Container>
    )
}

export default ModalComponent