import {signal, computed} from '@preact/signals';
import { route } from 'preact-router';
import Request from './request.controller';
import User from './user.controller';

export default class Auth{

    private static state = {
        authenticated: signal(false),
        authenticating: signal(false),
        token: signal("")
    }
    
    static async authenticate(email: string, password:string){

        //to implement
        //email verification before submit

        if(true){
            try{
                
            const {data, loggedIn} = await this.login(email, password)

            return data
            
            } catch(err){
                throw err
            }
        }
    }
    
    static manualAuthentication(user:any){
        this.state.authenticated.value = true
        this.state.authenticating.value = false
    }
    
    static toggleAuthenticationState(state:boolean){
        this.state.authenticating.value = state;
    }

    static async verifyCookie(){
        await Request.auth.get("/cookie")
    }
    
    private static async login(email:string, password:string){
        try {

            const {data} = await Request.auth.post("/signin", {email, pwd:password})
            this.state.token = data.IDToken
            this.state.authenticated.value = true
            // window.location  
            return {
                data,
                loggedIn: true
            }
            
        } catch(err){
            throw err
        }
    }
    
    
    static async logout(){
        try{
            await Request.auth.post("/logout")
            this.state.authenticated.value  = false
            this.state.authenticating.value = false
        } catch(err) {
            console.log(err)
            throw err
        }
    }
    
    static isAuthenticated(){
        return this.state.authenticated.value;
    }
    
    static isAuthenticating(){
        return this.state.authenticating.value;
    }
    
    static verifyRoutes(e:any){

        const isAuth = this.isAuthenticated();
        
        if(e.url == "/reset-password") return

        if(e.url == "/version") return

        if(isAuth && User.selectedCorp === null){
            return route("/corp-selection")
        }

        if(e.url != "/login" && !isAuth) return route("/login", true)

        return 
    }
    

}