import {h} from 'preact'
import Notifications from "../../controllers/notifications.controller"
import Container from '../ui/blocks/container.ui.block'



interface NotificationProps {
    position: "top" | "bottom"
}

const notificationColors = {
    success: "#a6e9d5",
    error: "#f1aeb5"
}


const NotificationComponent = (props:NotificationProps) => {
    
    const notifications = Notifications.getActiveNotifications();
    
    return (
        <Container position="fixed" zIndex={100} direction="column" padding="20px" gap="20px" pointerEvents='none' height="100vh">
            {notifications.map((not, i) => (
                <Container background={not.color} padding="20px" borderRadius="5px" pointerEvents='initial'>
                    {not.title} 
                </Container>
            ))}
        </Container>
    )
}

export default NotificationComponent