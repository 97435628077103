import { signal } from "@preact/signals"
import TranslationController from "../translation/controller"

export default class AppController {

	// private static _state = signal({
	// 	language: "ita",
	// 	languages: ["ita", "eng"] as const,
	// })

	private static languageLabels = {
		"ita": "IT",
		"eng": "EN"
	}

	private static _state = {
		language: signal(localStorage.getItem(TranslationController.localStorageLanguageKey) ?? "ita"),
		languages: signal(["ita", "eng"] as const)
	}

	static get state() {
		return this._state
	}

	static getLanguageLabel(lang: typeof this.state.languages.value[number]) {

		return this.languageLabels[lang]
	}

	static async sync() {

	}

	static get languages() {
		return this._state.languages.value
	}

	static get selectedLanguage() {
		return this._state.language
	}

	static setSelectedLanguage(lang: typeof this.state.languages.value[number]) {
		if (this.state.languages.value.includes(lang)) this._state.language.value = lang
		else console.log("LANGUAGE NOT AVAILABLE")
	}

}
