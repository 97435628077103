import {h} from 'preact'
import {css} from '@emotion/css'
import Colors from '../../../controllers/colors.controller';

interface ContainerProps {

    phoneRules?:string;

    children?: any
    onClick?: any;
            
    position?: "fixed" | "absolute" | "relative";
    zIndex?: number;
    display?:"flex" | "grid" | "inherit" | "none";
    direction?: "row" | "column";

    padding?: string | number;
    margin?: string | number;
    gap?:string;
    
    left?:string;
    right?:string;
    top?:string;
    bottom?:string;
        
        
                
    height?:string;
    width?: string;
    maxHeight?:string;
    maxWidth?: string;
    minWidth?:string;
    minHeight?: string;
    
    overflow?: string;
                
    flex?:string;
    flexBasis?:string;
    grow?: number
    shrink?: number
    textAlign?:string;
        
    justifyContent?: "space-between" | "space-around" | "center" | "initial" | "flex-start" | "flex-end";
    alignItems?: "space-between" | "space-around" | "center" | "initial" | "flex-start" | "flex-end";

    borderSize?:number;
    borderRadius?:string;
    borderColor?:string;
    background?: string;
    color?:string;
    pointerEvents?:string;
        
    hasShadows?:boolean;
    hoverable?: boolean;
    
    cursor?:string;
    
}

const Container = (props: ContainerProps) => {

    const colors = Colors.getColors()

    const container = css`
            box-sizing: border-box;
            position: ${props.position ?? "relative"};
            z-index: ${props.zIndex ?? 1};
            display: ${props.display ?? "flex"};
                        
            ${props.overflow ? `overflow: ${props.overflow};` : null};
            ${props.textAlign? `text-align: ${props.textAlign};` : null};

            flex: ${props.flex ?? "unset"};
            flex-direction: ${props.direction ?? "row"};
            flex-grow: ${props.grow ?? "unset"};
            flex-shrink: ${props.shrink ?? "unset"};
            ${props.flexBasis ? `flex-basis: ${props.flexBasis};` : null};

            ${props.justifyContent ? `justify-content: ${props.justifyContent};` : null};
            ${props.alignItems ? `align-items: ${props.alignItems};` : null};

            ${props.color ? `color: ${props.color};`: null}
            
            ${props.hasShadows ? `
                -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.07); 
                box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.07);`
            : null}

            pointer-events: ${props.pointerEvents ?? "initial"};
            padding: ${props.padding ?? "initial"};
            gap: ${props.gap ?? "unset"};
            width: ${props.width ?? "100%"};
            height: ${props.height ?? "auto"};
            
            ${props.left ? `left: ${props.left};`: null}
            ${props.right ? `right: ${props.right};`: null}
            ${props.top ? `top: ${props.top};`: null}
            ${props.bottom ? `bottom: ${props.bottom};`: null}

            ${props.background ? `background: ${props.background};`: null}

            border-radius: ${props.borderRadius ?? "0"};
            ${props.borderSize ? `border: ${props.borderSize}px solid ${props.borderColor ? props.borderColor : colors.foregroundColor};` : null };

            max-width: ${props.maxWidth ? props.maxWidth : "1140px" };
            min-width: ${props.minWidth ? props.minWidth : "unset" };
            max-height: ${props.maxHeight? props.maxHeight: "auto" };
            margin: ${props.margin ?? "unset"};
            ${props.cursor ? `cursor: ${props.cursor};` : null};
            ${props.hoverable ? `
                &:hover{
                    background: ${colors.foregroundColor.value};
                    color: ${colors.accentColor.value};
                    
                };
            `: ""};

            ${!!props.phoneRules ? `
                @media(max-width: 420px) {
                    ${props.phoneRules}
                }
            `: ""}
            
        `

    return <div class={container} onClick={props.onClick ?? null}>
        {props.children}
    </div>
}

export default Container